/* Third Party Modules */
import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { useMediaQuery } from "@chakra-ui/react";

/* Assets */
import kaiOnLake from "../assets/kaionlake.jpeg";
import thebeatles from "../assets/thebeatles.jpeg";
import goats from "../assets/bird.jpeg";
import fire from "../assets/bonfire.jpeg";

/* Custom Hooks */
import useParallaxPair from "./useParallaxPair";

/* Custom Components */
import FullPageTagLine from "./FullPageTagLine";
import ImageWithSideText from "./ImageWithSideText";
import Purpose from "./Purpose";
import Steps from "./Steps";
import ParallaxCircle from "./ParallaxCircle";
import { RiShipLine } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";
import useSlider from "./useSlider";

const Services = () => {
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");

  /* Refs */
  /* Parallax Effects */
  const [parallaxRefOne, triggerRefOne] = useParallaxPair("left");
  const [parallaxRefTwo, triggerRefTwo] = useParallaxPair("right", true);
  const [parallaxRefThree, triggerRefThree] = useParallaxPair("left", false);
  const [parallaxRefFour, triggerRefFour] = useParallaxPair("right", true);

  /* Sliding Effects */
  const sliderTriggerOne = useSlider("right");
  const sliderTriggerTwo = useSlider("right");
  const sliderTriggerThree = useSlider("left");
  const sliderTriggerFour = useSlider("left");

  /* Content */
  const kaiSideTest = {
    title: "An Undefined Community",
    content:
      "We are open to anyone willing to undefine the pre-conceived meanings of our very existence.",
  };

  const beatlesSideText = {
    title: "Allow Love",
    content:
      "We are not a religion; we allow revelations to happen. We are not a self-help group; we allow life to unfold into the marvelous and the unimaginable. We are not a healing center; we allow healing to happen. We are not an ideology; we allow truth to speak for itself. We are not a place to give you answers; we let questions dissolve.",
  };

  const goatsSideText = {
    title: "Abide in Non-dual Awareness",
    content:
      "We transcend the boundaries of good and evil, self and others. Our actions are not guided by any moral philosophy or religious doctrine but by our realization that we are all one.",
  };

  const awakenSideText = {
    title: "Annihilate Noise",
    content:
      "What remains once we strip away the veil of our personal histories, constant judgments, and servitude to society's agendas? This is not the place to encourage you to become a better version of yourself, but to become nothing-- to become everything.",
  };

  return (
    <>
      <Box id="TuneIn">
        <ImageWithSideText
          image={kaiOnLake}
          sideText={kaiSideTest}
          imageName="kai"
          backgroundColor="#d6936a"
          imageOnLeft={true}
          ref={(el) => {
            parallaxRefOne.current = el;
            sliderTriggerThree.current = el;
          }}
        />
        <FullPageTagLine
          tagline="Leave your self behind"
          ref={triggerRefOne}
          className="phil bgImage"
          backgroundColor="#fdfcf2"
          color="blackAlpha.700"
          fontSize={isLargerThan62 ? "9xl" : "6xl"}
        />
        <Purpose
          backgroundColor="#fdfcf2"
          content="We provide a space fostering unconditional acceptance
and <br/>deep bonding through our willingness to investigate and transcend humanity's illusory boundaries. "
          title="INFINITED HAS NO LABELS"
          color="blackAlpha.700"
          fontSize={isLargerThan62 ? "xl" : "md"}
        />
        <ImageWithSideText
          image={thebeatles}
          sideText={beatlesSideText}
          imageName="beatles"
          ref={(el) => {
            sliderTriggerOne.current = el;
            parallaxRefTwo.current = el;
          }}
          backgroundColor="#38516d"
          imageOnLeft={false}
        />

        <ImageWithSideText
          image={goats}
          sideText={goatsSideText}
          imageName="goats"
          ref={(el) => {
            triggerRefTwo.current = el;
            parallaxRefThree.current = el;
            sliderTriggerFour.current = el;
          }}
          backgroundColor="#aea4b5"
          imageOnLeft={true}
        />

        <ImageWithSideText
          image={fire}
          sideText={awakenSideText}
          imageName="fire"
          backgroundColor="#433128"
          imageOnLeft={false}
          ref={(el) => {
            triggerRefThree.current = el;
            parallaxRefFour.current = el;
            sliderTriggerTwo.current = el;
          }}
        />
        <Purpose
          ref={triggerRefFour}
          fontWeight={"bold"}
          title="Portals to Infinity"
          backgroundColor="#f3ede0"
          py={10}
          width={isLargerThan62 ? "40%" : "85%"}
          color="blackAlpha.700"
          fontSize={isLargerThan62 ? "lg" : "md"}
          content={
            ""
          }
        />
        <div style={{ position: "relative" }}>
     
        </div>
        <Steps bg={"#f3ede0"} />
   
      </Box>
    </>
  );
};

export default Services;

