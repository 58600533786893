import { Text, useMediaQuery, Center } from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import useIntersection from "./useIntersection";
import { MovingComponent } from "react-moving-text";

const Purpose = React.forwardRef((props, ref) => {
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");
  const content = props.content.split("<br/>").map((str) => <p>{str}</p>);

  const movingTextRef = useRef();
  const threshold = isLargerThan62 ? 0.2 : 0.1;
  const movingTextInViewPort = useIntersection(movingTextRef, "0px", threshold); // Trigger as soon as the element becomes visible
  const [hasMoved, setHasMoved] = useState(false);

  useEffect(() => {
    if (movingTextInViewPort) {
      setHasMoved(true);
    }
  }, [hasMoved, movingTextInViewPort]);

  return (
    <Center
      ref={(el) => {
        if (ref !== null) {
          ref.current = el;
        }
        movingTextRef.current = el;
      }}
      textAlign="center"
      py={10}
      px={20}
      className="purpose"
      flexDirection="column"
      backgroundColor={props.backgroundColor}
    >
      {hasMoved && (
        <MovingComponent
          type="fadeIn"
          duration="2000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <Text
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            color={props.fontColor}
          >
            {props.title}
          </Text>
          <Center>
            <Text
              fontSize={isLargerThan62 ? "md" : "sm"}
              fontWeight="normal"
              mt={6}
              mb={4}
              color="blackAlpah.800"
              w={props.width}
            >
              {content}
            </Text>{" "}
          </Center>
        </MovingComponent>
      )}
    </Center>
  );
});

export default Purpose;
