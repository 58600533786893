import React from "react";
import {
  Flex,
  Spacer,
  Text,
  useMediaQuery,
  Center,
  Image,
} from "@chakra-ui/react";
import { Parallax } from "react-scroll-parallax";

const ImageWithSideText = React.forwardRef((props, ref) => {
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");
  const content = props.sideText.content
    .split("<br/>")
    .map((str) => <p>{str}</p>);

  return (
    <>
      {props.imageOnLeft ? (
        <Flex
          width="full"
          height={"100vh"}
          alignItems="center"
          justifyContent="center"
          flexDirection={isLargerThanLG ? "row" : "column"}
          backgroundColor={props.backgroundColor}
          className="parallaxOuterContainer"
          ref={ref}
        >
          <Flex
            w={isLargerThanLG ? "65%" : "full"}
            id={props.imageName}
            className="parallaxOuterContainer"
          >
            <Parallax
              id={`${props.imageName}Parallax`}
              className="parallaxContainer"
              scale={[1.2, 1, "easeInQuad"]}
            >
              <Flex
                w={"full"}
                h={"full"}
                alignItems="center"
                justifyContent="center"
                className="parallaxInnerContainer"
              >
                <Image src={props.image} alt="Chakra Team" w="full" />
              </Flex>
            </Parallax>
          </Flex>
          <Spacer />
          {isLargerThanLG && (
            <Flex
              bg={props.backgroundColor}
              className="slider2"
              zIndex={"89"}
            />
          )}
          <Center
            className="sideText"
            backgroundColor={props.backgroundColor}
            w={isLargerThanLG ? "35%" : "full"}
            h={"full"}
            alignItems="left"
            flexDirection="column"
            pl={isLargerThanLG ? "7" : "2"}
            pr={isLargerThanLG ? "7" : "2"}
            color="white"
          >
            <Text fontSize={isLargerThanLG ? "4xl" : "2xl"} fontWeight="bold">
              {props.sideText.title}
            </Text>

            <Text mb="6" opacity="0.8" fontSize={isLargerThanLG ? "2xl" : "xl"}>
              {content}
            </Text>
          </Center>
        </Flex>
      ) : (
        <Flex
          height={"100vh"}
          // direction={{base: 'column-reverse'}}
          width="full"
          // minHeight="70vh"
          alignItems="center"
          // px={isLargerThanLG ? '16' : '6'}
          // py="16"
          justifyContent="center"
          flexDirection={isLargerThanLG ? "row" : "column-reverse"}
          className="parallaxOuterContainer"
          backgroundColor={props.backgroundColor}
          ref={ref}
        >
          <Center
            backgroundColor={props.backgroundColor}
            w={isLargerThanLG ? "35%" : "full"}
            flexDirection="column"
            pr={isLargerThanLG ? "7" : "2"}
            pl={isLargerThanLG ? "7" : "2"}
            color="white"
            h={"full"}
            alignItems="right"
            className="sideText"
          >
            <Text fontSize={isLargerThanLG ? "4xl" : "2xl"} fontWeight="bold">
              {props.sideText.title}
            </Text>

            <Text mb="6" opacity="0.8" fontSize={isLargerThanLG ? "2xl" : "xl"}>
              {content}
            </Text>
          </Center>
          {isLargerThanLG && (
            <Flex
              bg={props.backgroundColor}
              className="slider1"
              zIndex={"89"}
            />
          )}
          <Flex
            w={isLargerThanLG ? "65%" : "full"}
            id={props.imageName}
            className="parallaxOuterContainer"
          >
            <Parallax
              className="parallaxContainer"
              scale={[1.2, 1, "easeInQuad"]}
            >
              <Flex
                w={"full"}
                // mb={isLargerThanLG ? '0' : '6'}
                alignItems="center"
                justifyContent="center"
                className="parallaxInnerContainer"
              >
                <Image src={props.image} alt="Chakra Team" w="full" />
              </Flex>
            </Parallax>
          </Flex>
          <Spacer />
        </Flex>
      )}
    </>
  );
});

export default ImageWithSideText;
