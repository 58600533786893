import React from "react";
import FullPageTagLine from "./FullPageTagLine";
import { useRef, useEffect } from "react";
import useIntersection from "./useIntersection";
import BackgroundVideo from "./BackgroundVideo";
import { useMediaQuery } from "@chakra-ui/react";

const BackgroundVideoAndTagLine = () => {
  const ref = useRef();
  const inViewport = useIntersection(ref, "50%"); // Trigger as soon as the element becomes visible
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");

  const addOverLay = () => {
    const tagLineInfo = ref.current.getBoundingClientRect();
    const ratio = (1 - tagLineInfo.y / tagLineInfo.height) / 1.2;
    document
      .getElementById("video")
      .style.setProperty("--video-overlay", "rgba(0,0,0," + ratio + ")");
  };

  useEffect(() => {
    if (inViewport) {
      window.addEventListener("scroll", addOverLay);
    }
    return () => window.removeEventListener("scroll", addOverLay);
  }, [inViewport]);

  return (
    <>
      <BackgroundVideo />
      <FullPageTagLine
        id='phil'
        tagline="Infinited, a makerspace for the genuine truth seekers"
        ref={ref}
        inViewport={inViewport}
        className="phil"
        backgroundColor="#433128"
        fontSize={isLargerThan62 ? "6xl" : "5xl"}
        color="white"
      />
    </>
  );
};

export default BackgroundVideoAndTagLine;
