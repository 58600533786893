import FullPageTagLine from "./FullPageTagLine";
import BackgroundVideo from "./BackgroundVideo";
import BackgroundVideoAndTagLine from "./BackgroundVideoAndTagLine";
import ContactUs from "./ContactUs";
import Services from "./Services";
import Offerings from "./Offerings";

const Main = () => {
  return (
    <>
      <BackgroundVideoAndTagLine />
      {/*      // <BackgroundVideo />
      // <FullPageTagLine tagline="Infinited, a makerspace for the genuine truth seekers" />*/}
      <Services />
      <Offerings />
      <ContactUs />
    </>
  );
};

export default Main;
