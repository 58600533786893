import React from "react";
import vi from "../assets/InfiniteD.mp4";

const BackgroundVideo = () => {
  return (
    <div className="video-bg" id="video">
      <video
        // src="https://d1da1rdfkdmwsd.cloudfront.net/satnam1080_mute.mp4"
        src={vi}
        autoPlay
        loop
        muted
        playsInline
        type="video/mp4"
      />
    </div>
  );
};

export default BackgroundVideo;
