import { useState, useEffect, useRef } from "react";
import useIntersection from "./useIntersection";

const useParallaxPair = (direction, useSibling) => {
  const [hasShifted, setHasShift] = useState(false);
  const parallaxRef = useRef(null);
  const triggerRef = useRef(null);
  const inViewport = useIntersection(triggerRef, "0px");

  useEffect(() => {
    const elem = useSibling
      ? parallaxRef.current.firstElementChild.nextElementSibling
          .nextElementSibling
      : parallaxRef.current.firstElementChild;
    if (inViewport && !hasShifted) {
      elem.style.setProperty("position", "fixed");
      elem.style.setProperty("top", "-200px");
      elem.style.setProperty(direction, "0px");
      setHasShift(true);
    } else if (!inViewport && hasShifted) {
      elem.style.setProperty("position", "relative");
      elem.style.setProperty("top", "0px");
      setHasShift(false);
    }
  }, [inViewport, hasShifted, direction, useSibling]);
  return [parallaxRef, triggerRef];
};

export default useParallaxPair;
