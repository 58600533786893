import { Outlet } from "react-router-dom";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useDisclosure } from "@chakra-ui/react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import DrawerComponent from "./components/DrawerComponent";
import { useEffect } from "react";
import "@fontsource/ibm-plex-sans";
import theme from "./theme";

const Layout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  useEffect(() => {
    // Update the document title using the browser API
    try {
      var video = document.getElementById("video").scrollHeight;
      var nav = document.getElementById("nav").scrollHeight;
      document.getElementById("phil").style.marginTop = video - nav - 1 + "px";
    } catch (exception) {
      console.log(exception);
    }

    // if(left>right)
    // {
    //     document.getElementById('rightdiv').style.height=left;
    // }
    // else
    // {
    //     document.getElementById('leftdiv').style.height=right;
    // }
  });
  return (
    <ChakraProvider theme={theme}>
      <Nav ref={btnRef} onOpen={onOpen} />
      <Outlet />
      <Footer />
      <DrawerComponent isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </ChakraProvider>
  );
};

export default Layout;
