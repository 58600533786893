import "./App.css";
import Layout from "./Layout";
import Services from "./components/Services";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="Tunein" element={<Services />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
