import {
  Flex,
  FormControl,
  Text,
  Input,
  Textarea,
  Button,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React from "react";

const ContactUs = () => {
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");
  const toast = useToast();
  const submitForm = () => {
    return toast({
      title: "Message sent!🚀",
      description: "Thank you for contacting us!",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  return (
    <Flex
      id="Reachout"
      w="full"
      minHeight="90vh"
      py="16"
      px={isLargerThanLG ? "16" : "6"}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundColor="#fdfcf2"
    >
      <Text
        fontSize={isLargerThanLG ? "4xl" : "3xl"}
        fontWeight="light"
        mt={6}
        mb={4}
        color="blackAlpha.900"
      >
        Take the Step
      </Text>

      <FormControl
        w={isLargerThanLG ? "60%" : "full"}
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Input
          id="Name"
          type="text"
          placeholder="Name"
          mb="5"
          h="14"
          _placeholder={{ color: "gray.900" }}
        />

        <Input
          id="email"
          type="email"
          placeholder="Email"
          mb="5"
          h="14"
          _placeholder={{ color: "gray.900" }}
        />

        <Input
          id="subject"
          type="text"
          placeholder="Subject"
          mb="5"
          h="14"
          _placeholder={{ color: "gray.900" }}
        />

        <Textarea
          placeholder="Enter a message"
          mb="5"
          rows={7}
          p="5"
          _placeholder={{ color: "gray.900" }}
        />

        <Button
          backgroundColor="#284331"
          size="lg"
          textAlign="left"
          width="200px"
          color="white"
          type="submit"
          onClick={submitForm}
        >
          SUBMIT
        </Button>
      </FormControl>
    </Flex>
  );
};

export default ContactUs;
