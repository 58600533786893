import { Box, useMediaQuery, Flex } from "@chakra-ui/react";
import { RiNumber0, RiNumber1, RiNumber2 } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";
import Step from "./Step";
import React, { useRef, useState, useEffect } from "react";
import useIntersection from "./useIntersection";

// import { Text, useMediaQuery, Center } from "@chakra-ui/react";
// import React from "react";

const Steps = (props) => {
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");

  const movingTextRef = useRef();
  const threshold = isLargerThan62 ? 0.2 : 0.1;
  const movingTextInViewPort = useIntersection(movingTextRef, "0px", threshold); // Trigger as soon as the element becomes visible
  const [hasMoved, setHasMoved] = useState(false);

  const [animationIndex, setAnimationIndex] = useState(0);
  const handleChainAnimation = () => {
    setAnimationIndex(animationIndex + 1);
  };

  useEffect(() => {
    if (movingTextInViewPort) {
      setHasMoved(true);
    }
  }, [hasMoved, movingTextInViewPort]);

  // const content = props.content.split('<br/>').map(str => <p>{str}</p>);
  const steps = [
    <Step
      hasMoved={hasMoved}
      stepIndex={0}
      animationIndex={animationIndex}
      handleChainAnimation={handleChainAnimation}
      
      title="Unconditional Love"
      content="Unconditional love is not a concept confined to the enlightened or dogs; it is within reach through the smallest actions of your everyday life. Can you embrace the sensation of your anxiety? Can you permit the negative thought patterns to simply exist without attempting to supress or redirect them? Have you contemplated the idea that all these unwanted energy within you are like neglected children, constantly returning to you in search of love and attention they have never received before? Can you drop your preferences for “good’ and your urges to fix the “bad”?  To practice unconditional love is to cease to be the director and judge of your inner state. "
      {...props}
    />,
    <Step
      hasMoved={hasMoved}
      stepIndex={1}
      animationIndex={animationIndex}
      handleChainAnimation={handleChainAnimation}
      title="Here and Now"
      content="There are infinite possibilities lies in the here and now. Yet, the past continuously project itself into future, continuing on the same path, where stagnation and anxiety arises.  Open your eyes to the FACT that no matter how good or terrible the past is, that ship has sailed. And the future will never come. The only true choice you have, is at this very moment - do you choose love, or do you choose conflict? Within love, infinty awaits."
      {...props}
    />,
    <Step
      hasMoved={hasMoved}
      stepIndex={2}
      animationIndex={animationIndex}
      handleChainAnimation={handleChainAnimation}
      title="Not a Choice"
      content="Ask yourself, what other choice do you have? do you want to play a game in which you didn't make the rules? Do you want to play a game where participation wasn't consented? Do you want what society wants you to want? do you understand why you are resisting the things you don't want? Is life really just a petty game of collecting positive experiences? or it could be something more profound? What other choice do you have, rather to choose the easing of all inner conflict and see where the saved life energy takes you?"
      {...props}
    />,
  ];

  return (
    <Box style={{ position: "relative" }} {...props}>
      <Flex
        spacing={0}
        zIndex="201"
        flexDirection={isLargerThan62 ? "row" : "column"}
        ref={movingTextRef}
        className={isLargerThan62 && "step"}
      >
        {steps.map((step) => (
          <Box
            bg={props.bg}
            height="auto"
            zIndex="201"
            py={isLargerThan62 ? "40" : "5"}
            w={isLargerThan62 ? "33%" : "100%"}
          >
            {step}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
export default Steps;
