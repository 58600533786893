import React from "react";
import {
  Text,
  Flex,
  Spacer,
  IconButton,
  useMediaQuery,
  Link,
  HStack,
} from "@chakra-ui/react";
import { FaAlignJustify } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";
import { HashLink as RouterLink } from "react-router-hash-link";

const Links = ["Tune In", "Reach out"];

const Nav = ({ onOpen, ref }) => {
  const navBg = "rgba(255, 255, 255, 0.0)";
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");

  return (
    <Flex
      id="nav"
      h="8vh"
      alignItems="center"
      p="6"
      boxShadow={"none"}
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      bg={navBg}
    >
      <Text fontSize="2xl" fontWeight="bold">
        <Link href="/" _hover={{ textDecoration: "none" }} color={"white"}>
          Infinited
        </Link>
      </Text>

      <Spacer />

      <Flex alignItems="center">
        {isLargerThanMD ? (
          <HStack spacing={8} alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <RouterLink
                  location={{
                    pathname:
                      document.location.pathname + document.location.hash,
                  }}
                  color={"white"}
                  className="navLink"
                  key={link}
                  to={`/#${link.replace(/\s/g, "")}`}
                  activeClassName="selected"
                >
                  <Link
                    px={2}
                    py={1}
                    color={"white"}
                    rounded={"md"}
                    _hover={{
                      textDecoration: "none",
                      bg: "gray.700",
                    }}
                    fontSize="xl"
                  >
                    {link}
                  </Link>
                </RouterLink>
              ))}
            </HStack>
          </HStack>
        ) : (
          <IconButton ref={ref} onClick={onOpen}>
            <Icon as={FaAlignJustify} />
          </IconButton>
        )}
      </Flex>
    </Flex>
  );
};

export default Nav;
