import { Text, Center, useMediaQuery } from "@chakra-ui/react";
import { MovingComponent } from "react-moving-text";
import React, { useRef, useState, useEffect } from "react";
import useIntersection from "./useIntersection";

const FullPageTagLine = React.forwardRef((props, ref) => {
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");
  const movingTextRef = useRef();
  const threshold = isLargerThan62 ? 0.5 : 0.2;
  const movingTextInViewPort = useIntersection(movingTextRef, "0px", threshold); // Trigger as soon as the element becomes visible
  const [hasMoved, setHasMoved] = useState(false);

  useEffect(() => {
    if (movingTextInViewPort) {
      setHasMoved(true);
    }
  }, [hasMoved, movingTextInViewPort]);

  return (
    <Center
      ref={(el) => {
        ref.current = el;
        movingTextRef.current = el;
      }}
      textAlign="center"
      py={10}
      px={6}
      id={props.id}
      backgroundColor={props.backgroundColor}
      className={props.className}
    >
      {" "}
      <Text
        fontSize={props.fontSize}
        fontWeight="bold"
        mt={6}
        mb={4}
        color={props.color}
        zIndex={9}
        ref={movingTextRef}
      >
        {hasMoved && (
          <MovingComponent
            type="fadeInFromBottom"
            duration="2000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
          >
            {props.tagline}
          </MovingComponent>
        )}
      </Text>
    </Center>
  );
});

export default FullPageTagLine;
