import { useState, useEffect, useRef } from "react";
import useIntersection from "./useIntersection";
import { useMediaQuery } from "@chakra-ui/react";

const useSlider = (direction) => {
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");
  const ref = useRef(null);
  const inViewport = useIntersection(ref, "0px", 0.1); // Trigger as soon as the element becomes visible
  if (inViewport) {
    console.log(ref);
    console.log("is in inViewport");
  }
  const [slided, setSlided] = useState(false);
  useEffect(() => {
    if (isLargerThanLG) {
      const elem =
        direction === "left"
          ? ref.current.firstElementChild.nextElementSibling.nextElementSibling
          : ref.current.firstElementChild.nextElementSibling;
      if (inViewport && !slided) {
        elem.classList.add("slided");
        setSlided(true);
      }
    }
  }, [slided, inViewport, direction, isLargerThanLG]);
  return ref;
};

export default useSlider;
