import { Center, Text, useMediaQuery } from "@chakra-ui/react";
import MovingText from "react-moving-text";
import React from "react";

const Step = (props) => {
  const [isLargerThan62] = useMediaQuery("(min-width: 62em)");
  const {
    title,
    content,
    numIcon,
    delay,
    hasMoved,
    stepIndex,
    animationIndex,
    handleChainAnimation,
  } = props;
  const shouldAnimate = animationIndex === stepIndex;

  if (hasMoved && shouldAnimate && isLargerThan62) {
    return (
      <MovingText
        type="fadeInFromLeft"
        onAnimationEnd={handleChainAnimation}
        duration="1000ms"
        delay={"0s"}
        direction="normal"
        timing="ease"
        iteration="1"
        fillMode="none"
      >
        <Center
          textAlign="center"
          px={20}
          flexDirection="column"
          className="purpose"
        >
          {numIcon}
          <Text
            fontSize={isLargerThan62 ? "2xl" : "xl"}
            fontWeight="bold"
            color="blackAlpha.500"
          >
            {title}
          </Text>
          <Text
            fontSize={isLargerThan62 ? "xl" : "md"}
            fontWeight="normal"
            mt={6}
            mb={4}
            color="blackAlpha.800"
          >
            {content}
          </Text>
        </Center>
      </MovingText>
    );
  } else if ((hasMoved && animationIndex > stepIndex) || !isLargerThan62) {
    return (
      <Center
        textAlign="center"
        px={20}
        flexDirection="column"
        className="purpose"
      >
        {numIcon}
        <Text
          fontSize={isLargerThan62 ? "2xl" : "xl"}
          fontWeight="bold"
          color="blackAlpha.500"
        >
          {title}
        </Text>
        <Text
          fontSize={isLargerThan62 ? "xl" : "md"}
          fontWeight="normal"
          mt={6}
          mb={4}
          color="blackAlpha.800"
        >
          {content}
        </Text>
      </Center>
    );
  }
};
export default Step;
