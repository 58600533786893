import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Image,
  Heading,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import yoga from "../assets/yoga.jpg";
import bee from "../assets/bee.jpg";
import sus from "../assets/sus.jpg";
import { GiDeadWood } from "react-icons/gi";
import { GiFullWoodBucketHandle } from "react-icons/gi";
import { useState, useEffect } from "react";

interface FeatureProps {
  title: string;
  text: string;
  image: ReactElement;
}

const Feature = ({
  title,
  text,
  image,
  onMouseEnter,
  onMouseLeave,
  index,
  hovers,
}: FeatureProps) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  return (
    <Stack
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      opacity={hovers[index] ? 1 : 0.75}
    >
      <Flex
        w={isLargerThanMD ? '30vw' : '90vw'}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"md"}
        bg={"#303130"}
      >
        {image}
      </Flex>
      <Flex justify={"left"} align={"left"} textAlign="left">
        <Text fontWeight={600} color={"white"}>
          {title}
        </Text>
      </Flex>
      <Flex justify={"left"} align={"center"}>
        <Text color={"gray.600"} color={"white"} minHeight={180}>
          {text}
        </Text>
      </Flex>
      <Flex justify={"left"} align={"center"}>
        <Link
          color="#968361"
          href="#"
          onClick={(event) => event.preventDefault()}
        >
          I want to partake
        </Link>
      </Flex>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  const [hovers, setHovers] = useState([false, false, false]);

  return (
    <Box p={4} bg={"#303130"} pos='relative'>
      <Flex justify={"center"} align={"center"}  mt={20}>
        <Heading
          as="h1"
          size="md"
          noOfLines={1}
          py={4}
          color="white"
          fontWeight="bold"
        >
          Chop Wood, Carry Water
        </Heading>
      </Flex>

      <Flex justify={"center"} align={"center"} textAlign={"center"} mb={40}>
        <Text color="white" size="md">
          “Before enlightenment; chop wood, carry water. After enlightenment; chop wood, carry water.” 
          <br />
          Explore our activities and integrate the Zen Kōan to life. 
          <br />
          Along the way, make each other's heart a little warmer.
        </Text>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} className="offer">
        <Feature
          index={0}
          hovers={hovers}
          onMouseEnter={() => setHovers([true, false, false])}
          onMouseLeave={() => setHovers([false, true, true])}
          image={<Image src={yoga} alt="yoga" />}
          title={"Yoga"}
          text={
            "The word Yoga means union. For some, it means the union of the body and the soul. For us, it means the union of the soul with the unknown. Our yoga session are suitable for all physical conditions -- the core of our yoga is breathing techniques. Nourish your soul, and reap the side benefits of a stronger body and lessened stress."
          }
        />
        <Feature
          index={1}
          onMouseEnter={() => setHovers([false, true, false])}
          onMouseLeave={() => setHovers([true, false, true])}
          hovers={hovers}
          image={<Image src={bee} alt="bee" />}
          title={"Beekeeping"}
          text={
            "Beekeeping provides a unique opportunity to communicate with nature. Bees are fascinating creatures that are integral to the ecosystem and have a complex social structure. By caring and observing them, you will not only get a front row seat of the magical hive mind, but also ensuring the survival of important insects, and maintaining the biodiversity of the local ecosystem."
          }
        />
        <Feature
          onMouseEnter={() => setHovers([false, false, true])}
          onMouseLeave={() => setHovers([true, true, false])}
          index={2}
          hovers={hovers}
          image={<Image src={sus} alt="sustainability" />}
          title={"A Sanctuary of Solitude"}
          text={
            '"A man can be himself only so long as he is alone, and if he does not love solitude, he will not love freedom, for it is only when he is alone that he is really free" - Arthur Schopenhauer. Book a vacation away from the lures of endless sensory pleasures and embrace the opposite of what society wants you to be. Discover the unknown beauty of being you, undisturbed.'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
